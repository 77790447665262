<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import { Icon } from "@iconify/vue2";
import Multiselect from "vue-multiselect";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Rezervasyonlar",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Icon, Multiselect },
  data() {
    return {
      isBusy: false,
      title: "Rezervasyonlar",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Rezervasyonlar",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 0,
      fields: [
        { key: "id", sortable: true, label: "#" },
        { key: "agency.company_name", sortable: true, label: "Acenta" },
        { key: "customer.customer_name", sortable: true, label: "Müşteri" },
        {
          key: "reservation_type.label",
          sortable: true,
          label: "Rezervasyon Türü",
        },
        { key: "status", sortable: true, label: "Rezervasyon Durumu" },
        { key: "updated_at", sortable: true, label: "Son Güncelleme" },
        { key: "actions", label: "İşlemler" },
      ],
      meta: [],
      agency: [],
      customer: [],
      values: {
        agency:0,
        customer:0,
        confirme:0,
        start_date: 0,
        end_date: 0,
      },
    };
  },
  created(){
    this.getAgency(), this.getCustomer();
  },
  methods: {
    getAgency(){
      const api_url = `${process.env.VUE_APP_BASEURL}/agency/getAll`;

      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data) {
            this.agency = response.data.data
          }
        })
        .catch((error) => {
          console.error("Veri yüklenirken bir hata oluştu:", error);
        });
    },
    getCustomer(){
      const api_url = `${process.env.VUE_APP_BASEURL}/customer/getAll`;

      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data) {
            this.customer = response.data.data
          }
        })
        .catch((error) => {
          console.error("Veri yüklenirken bir hata oluştu:", error);
        });
    },
    sorgula() {
      this.$refs.btable.refresh();
    },
    daily(){
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Ay sıfırdan başlar, bu yüzden +1
      const day = String(today.getDate()).padStart(2, '0'); // Gün değeri

      this.values.start_date = `${year}-${month}-${day}`;
      this.$refs.btable.refresh();
    },
    weekly(){
      const todayW = new Date();
      const today = new Date();
      todayW.setDate(today.getDate() + 7); // Bir hafta (7 gün) geri git

      const year = todayW.getFullYear();

      const monthW = String(todayW.getMonth() + 1).padStart(2, '0'); // Ay sıfırdan başlar, bu yüzden +1
      const dayW = String(todayW.getDate()).padStart(2, '0'); // Gün değeri

      const month = String(today.getMonth() + 1).padStart(2, '0'); // Ay sıfırdan başlar, bu yüzden +1
      const day = String(today.getDate()).padStart(2, '0'); // Gün değeri

      this.values.start_date = `${year}-${month}-${day}`; // YYYY-MM-DD formatı
      this.values.end_date = `${year}-${monthW}-${dayW}`; // YYYY-MM-DD formatı
      this.$refs.btable.refresh();
    },  
    monthly(){
      const today = new Date();
      const todayW = new Date();
      today.setMonth(today.getMonth() + 1); // Bir ay geri git
      
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Ay sıfırdan başlar, bu yüzden +1
      const day = String(today.getDate()).padStart(2, '0'); // Gün değeri

      const monthW = String(todayW.getMonth() + 1).padStart(2, '0'); // Ay sıfırdan başlar, bu yüzden +1

      this.values.start_date = `${year}-${monthW}-${day}`; // YYYY-MM-DD formatı
      this.values.end_date = `${year}-${month}-${day}`; // YYYY-MM-DD formatı
      this.$refs.btable.refresh();
    },
    getData(ctx, callback) {
      this.isBusy = true;
      const params = "?page=" + ctx.currentPage;
      const data = {
        agency: this.values.agency.id ? this.values.agency.id : 0,
        customer: this.values.customer.id ? this.values.customer.id : 0,
        confirme: this.values.confirme ? this.values.confirme : 0,
        start_date: this.values.start_date ? this.values.start_date : 0,
        end_date: this.values.end_date ? this.values.end_date : 0,
      }
      axios
        .post(process.env.VUE_APP_BASEURL + "/reservation/filter" + params, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data) {
            const items = response.data.data;
            this.totalRows = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            callback(items);
          }
        })
        .catch((error) => {
          callback([]);
          console.error("Veri yüklenirken bir hata oluştu:", error);
        });
        this.isBusy = false;
      return;
    },
    onPageChange(newPage) {
      this.currentPage = newPage;
    },
    editHotel(itemId) {
      this.$router.push(`/reservation/edit/${itemId}`);
    },
    deleteHotel(itemId) {
      const api_url = process.env.VUE_APP_BASEURL + `/reservation/${itemId}`;
      axios
        .delete(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="sorgula">
              <div class="row">

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="email">Acenta</label>
                    <multiselect v-model="values.agency" :options="agency" label="company_name">
                    </multiselect>
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="email">Müşteri</label>
                    <multiselect v-model="values.customer" :options="customer" label="customer_name">
                    </multiselect>
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="email">Giriş Tarihi</label>
                    <input type="date" v-model="values.start_date" class="form-control" />
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="email">Çıkış Tarihi</label>
                    <input type="date" v-model="values.end_date" class="form-control" />
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="email">Confirme</label>
                    <input type="text" v-model="values.confirme" class="form-control" />
                  </div>
                </div>

                <div class="col-12">
                  <div class="d-flex justify-content-end align-items-center">
                    <button type="submit" class="btn btn-primary px-4">Ara</button>
                  </div>
                </div>

              </div>
            </form>

            <div class="row">
              <div class="col-md-2">
                <button class="btn btn-primary w-100" @click="daily">Günlük</button>
              </div>
              <div class="col-md-2">
                <button class="btn btn-primary w-100" @click="weekly">Haftalık</button>
              </div>
              <div class="col-md-2">
                <button class="btn btn-primary w-100" @click="monthly">Aylık</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="col-12 d-flex justify-content-between align-items-center mb-3">
              <h4 class="card-title m-0">Rezervasyonlar</h4>
              <router-link to="/reservation/add" class="btn btn-primary">
                <Icon icon="lucide:plus-circle" class="mr-1" /> Yeni Rezervasyon
              </router-link>
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="getData" ref="btable" :fields="fields" :per-page="10" :total-rows="totalRows" responsive="sm"
                :current-page="currentPage" :busy.sync="isBusy">
                <template #table-busy>
                  <div class="d-flex flex-column align-items-center justify-content-center gap-1 my-2">
                    <Icon icon="lucide:loader" class="spinner-rotate" :style="{ fontSize: '72px' }" />
                  </div>
                </template>
                <template #cell(updated_at)="data">
                  {{ data.item.updated_at | moment("DD.MM.YYYY HH:mm") }}
                </template>
                <template #cell(status)="data">
                  <b-badge :variant="data.item.status.variant">
                    {{ data.item.status.label }}
                  </b-badge>
                </template>
                <template v-slot:cell(actions)="data">
                  <button @click="editHotel(data.item.id)" class="btn btn-sm btn-secondary">
                    Düzenle
                  </button>
                  <button @click="deleteHotel(data.item.id)" class="btn btn-sm btn-danger ml-2">
                    Sil
                  </button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                      @change="onPageChange"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>